//
//	Subheader
//

.subheader {
	@include padding(map-get($subheader, padding-y) map-get($subheader, padding-x));
	@include backdrop-filter-blur(map-get($subheader, blur));

	position: sticky;
	z-index: round($zindex-sticky - 1);
	top: 0;
	display: flex;
	min-height: map-get($subheader, height);
	align-items: center;
	border-bottom: map-get($subheader, border-size) solid map-get($subheader, border-color);
	margin-top: map-get($content, padding-y) * -1;
	margin-bottom: map-get($content, padding-y);
	background-color: rgba(map-get($subheader, background-color), map-get($subheader, opacity));
	box-shadow: map-get($header, box-shadow);
	// stylelint-disable declaration-colon-newline-after
	color: if(
		map-get($subheader, color),
		map-get($subheader, color),
		color-contrast(map-get($subheader, background-color), map-get($subheader, color-dark))
	);
	// stylelint-enable declaration-colon-newline-after

	@include dark-mode {
		background-color: rgba(
			map-get($subheader, dark-background-color),
			map-get($subheader, opacity)
		);
		border-bottom-color: map-get($subheader, dark-border-color);
		color: if(
			map-get($subheader, color),
			color-contrast(
				map-get($subheader, dark-background-color),
				map-get($subheader, color-dark)
			),
			map-get($subheader, color)
		);
	}

	@at-root .header + .content & {
		top: map-get($header, height);
	}

	@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
		min-height: fit-content;

		> * {
			@include padding-top($spacer);
			@include padding-bottom($spacer);
		}
	}
}

// If header is fly
@include header-modern() {
	.modern-design .subheader {
		// stylelint-disable-line
		@include margin-left($grid-gutter-width * 0.5);
		@include margin-right($grid-gutter-width * 0.5);
		@include border-radius($modern-design-radius);

		border-bottom: 0;
	}
}

.subheader-left {
	@include child-space();

	display: flex;
	align-items: center;

	@include media-breakpoint-down(sm) {
		@include child-space-bottom();

		flex-direction: column;
		align-items: flex-start;
	}
}

.subheader-right {
	@include child-space();

	display: flex;
	align-items: center;
}

.subheader-separator {
	height: 2rem;
	border-left: 1px solid map-deep-get($subheader, separator, border-color);

	@include media-breakpoint-down(sm) {
		display: none;
	}
}
