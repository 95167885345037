//
//  Aside
//

aside.aside.open.aside-touch-bar.aside-touch-bar-open {
	background-color: #66baeb;
}

.aside {
	position: fixed;
	z-index: $zindex-aside;
	top: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	border-right: map-get($aside, border-size) solid map-get($aside, border-color);
    background-color: #66baeb;
	color: color-contrast(map-get($aside, background-color));
	transition: $transition-base;

	// Mobile Status
	@include aside-close(true) {
		@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
			// stylelint-disable declaration-colon-newline-after
			left: calc(
				(
						#{map-deep-get($aside, width)} + #{if(
								map-get($aside, border-size) == 0,
								#{0px},
								#{map-get($aside, border-size)}
							)}
					) * -1
			);
			// stylelint-enable declaration-colon-newline-after
		}
	}

	@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
		width: map-get($aside, width);
	}

	//  Status
	&.open,
	&:hover {
		width: map-get($aside, width);
	}

	&:not(.open):hover {
		@include backdrop-filter-blur(map-deep-get($aside, hover, blur));
		// stylelint-disable declaration-colon-newline-after
		background-color: rgba(
			map-get($aside, background-color),
			map-deep-get($aside, hover, opacity)
		);
		// stylelint-enable declaration-colon-newline-after
	}

	&-head {
		@include padding(
			map-deep-get($aside, head, padding-y) map-deep-get($aside, head, padding-x)
		);
	}

	&-body {
		@include padding(
			map-deep-get($aside, body, padding-y) map-deep-get($aside, body, padding-x)
		);

		display: flex;
		overflow: auto;
		height: 100%;
		flex-direction: column;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&-foot {
		@include padding(
			map-deep-get($aside, foot, padding-y) map-deep-get($aside, foot, padding-x) 0
		);
	}
}

.aside-touch {
	position: fixed;
	top: calc(50% - 5rem);
	left: map-get($aside, width) + $modern-design-size + $modern-design-size * 0.5;
	width: 0.5rem;
	height: 10rem;
	background-color: rgba($dark, 0.5);
	border-radius: $border-radius;
	box-shadow: $box-shadow-lg;
	cursor: pointer;
	@include dark-mode() {
		background-color: rgba($light, 0.5);
	}
}

.aside-drag-area {
	position: absolute;
	top: 0;
	left: $modern-design-size * 0.5;
	width: map-get($aside, width) + $modern-design-size + $modern-design-size * 0.5 - 0.25rem;
	height: 100vh;
}

// stylelint-disable no-descending-specificity
.modern-design .aside {
	@include aside-modern() {
		@include margin($modern-design-size);
		@include border-radius($modern-design-radius);

		@if map-deep-get($aside, shadow) {
			@include box-shadow($modern-design-shadow);
		}

		overflow: hidden;
	}
}
// stylelint-enable no-descending-specificity
@media only screen and (max-width: 1023px) {
	.modern-design .aside {
		width: 15%;
		border-radius: 1rem;
	}
}